import { DateTime } from "luxon";
import moment from "moment-timezone";
import { mapState as mapPiniaState } from "pinia";
import { mapActions, mapState } from "pinia";
import Vue from "vue";

import { useAppointmentsStore } from "@/pinia-store/appointments";
import { useAuthStore } from "@/pinia-store/auth";
import { useEncountersStore } from "@/pinia-store/encounters";
import { useRegistrarsStore } from "@/pinia-store/registrars";
import { RolesEnum } from "@/types/Roles.enum";

export const upcomingPatientListMixin = Vue.extend({
  data: () => ({
    dialog: false,
    loading: false,
    cancelledId: "",
  }),
  computed: {
    ...mapState(useEncountersStore, ["encounters", "upcomingTabFilter"]),
    ...mapState(useAppointmentsStore, ["upcomingAppointments"]),
    ...mapState(useRegistrarsStore, ["registrarPractitioners"]),
    ...mapPiniaState(useAuthStore, ["role", "uid", "timeZone"]),
    upcomingItems() {
      let now = this.$moment.tz(this.timeZone);
      const allItems = [...(this.upcomingAppointments || [])];
      allItems.sort((a, b) => new Date(a.start) - new Date(b.start));
      return allItems
        .filter((e) => this.$moment(e.end).isAfter(now))
        .reduce((acc, val) => {
          const currentDate = DateTime.fromISO(val.start).toFormat("cccc, d MMMM yyyy");
          const foundIndex = acc.findIndex((el) => el.date === currentDate);
          if (foundIndex === -1) {
            acc.push({ date: currentDate, items: [val] });
          } else {
            acc[foundIndex].items.push(val);
          }
          return acc;
        }, [])
        .sort((a, b) => new Date(a.date) - new Date(b.date));
    },
  },
  methods: {
    ...mapActions(useEncountersStore, ["getAllEncounters"]),
    ...mapActions(useRegistrarsStore, ["getRegistrarPractitioners"]),
    joinRoom(encounterId) {
      this.$router.push(`/${this.role}/encounters/${encounterId}`);
    },
    onCancel(id) {
      this.cancelledId = id;
      this.dialog = true;
    },
    async getListEncounters() {
      this.loading = true;
      try {
        let { currentDate } = this.upcomingTabFilter;
        const datesSorted = currentDate.sort((a, b) => moment(a).valueOf() - moment(b).valueOf());
        let queryParams = {
          future: true,
          patientId: this.uid,
          practitionerId: this.upcomingTabFilter.practitionerId,
        };
        if (datesSorted.length === 2) {
          queryParams.startDate = datesSorted[0];
          queryParams.endDate = datesSorted[1];
        }
        await this.getAllEncounters(queryParams);
      } catch (e) {
        console.error(e);
      }
      this.loading = false;
    },
  },
  async mounted() {
    await this.getListEncounters();
    if (this.role === RolesEnum.Registrar) {
      await this.getRegistrarPractitioners(this.uid);
    }
  },
});
