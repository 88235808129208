<template>
  <div>
    <div class="container-filters">
      <div class="d-flex pa-0 align-center mt-3">
        <p class="ma-0 mr-2">Doctors:</p>
        <v-select
          :disabled="isLoading || loading"
          :items="practitionersList"
          :value="upcomingTabFilter.patientId"
          dense
          height="32px"
          hide-details="true"
          outlined
          placeholder="All"
          @change="changeSelectedPractitionerData($event)"
        ></v-select>
      </div>
      <div class="d-flex pa-0 align-center mt-3">
        <p class="ma-0 mr-2">Date Range:</p>
        <RangeDatePicker
          v-model="upcomingTabFilter.currentDate"
          :clearRangeDatePicker="false"
          :dense="true"
          :height="34"
          :hide-title="true"
          :hideDetails="true"
          :isYearFirst="false"
          className="date-picker__box-shadow v-input--hide-details--custom"
          @setDate="changeCurrentDate"
        />
      </div>
    </div>
    <div>
      <v-progress-linear v-if="loading || isLoading" color="primary" indeterminate />
      <div v-else>
        <div v-if="!upcomingItems.length" class="ap-list_no-items">
          <img alt="No Items" src="@/assets/no-items.svg" />
          <p class="heading-4">There is no appointments to display here</p>
          <p class="sub-heading-3">Book a visit with a doctor first.</p>
          <OutlinedButton
            color="primary"
            style="color: #2196f3; opacity: 1; border: 1px solid #2196f3"
            text="Schedule a New Visit"
            @onClick="$emit('toSchedule')"
          />
        </div>
        <div class="ap-list">
          <div v-for="{ date, items } in upcomingItems" :key="date" class="ap-list">
            <h1 class="ap-list_wrapTitle">{{ date }}</h1>
            <AppointmentsListItem
              v-for="encounter in items"
              :key="encounter.id"
              :item="encounter"
              place="upcoming"
              role="patient"
              @onButtonClick="joinRoom"
              @onCancel="onCancel"
            />
          </div>
        </div>
      </div>
      <CancelVisit v-model="dialog" :appointmentId="cancelledId" @success="getPatientUpcomingEncounters" />
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import { mapActions, mapState } from "pinia";

import AppointmentsListItem from "@/components/AppointmentsListItem";
import CancelVisit from "@/components/CancelVisit";
import RangeDatePicker from "@/components/shared/RangeDatePicker";
import OutlinedButton from "@/components/uikit/OutlinedButton";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { upcomingPatientListMixin } from "@/mixins/upcomingPatientList";
import { useAuthStore } from "@/pinia-store/auth";
import { useEncountersStore } from "@/pinia-store/encounters";
import { usePatientsStore } from "@/pinia-store/patients";

export default {
  name: "Upcoming",
  props: ["isLoading", "isSwitchTab"],
  data: () => ({
    currentDate: [moment().format("YYYY-MM-DD"), moment().add(7, "days").format("YYYY-MM-DD")],
    practitionerId: null,
    clearRangeDatePicker: false,
  }),
  components: {
    RangeDatePicker,
    AppointmentsListItem,
    CancelVisit,
    OutlinedButton,
  },
  mixins: [upcomingPatientListMixin],
  computed: {
    ...mapState(useEncountersStore, ["upcomingTabFilter"]),
    ...mapState(usePatientsStore, ["practitioners"]),
    ...mapState(useAuthStore, ["uid"]),
    ...mapState(useAuthStore, ["timeZone"]),
    datePickerValue() {
      const startWeek = moment(this.currentDate).startOf("isoWeek").weekday(1).format("MMMM, D");
      const endWeek = moment(this.currentDate).endOf("isoWeek").weekday(1).format("MMMM, D");
      return `${startWeek} - ${endWeek}`;
    },
    practitionersList() {
      const practitioners = this.practitioners?.map
        ? this.practitioners.map(({ firstName, lastName, id }) => ({
            text: `${firstName} ${lastName}`,
            value: id,
          }))
        : [];
      practitioners.unshift({
        text: "All",
        value: null,
      });
      return practitioners;
    },
  },

  async mounted() {
    await this.getPatientUpcomingEncounters();
  },
  methods: {
    ...mapActions(useEncountersStore, ["setUpcomingTabFilter"]),
    ...mapActions(useEncountersStore, ["getAllEncounters", "getPatientUpcomingEncounters"]),
    async changeSelectedPractitionerData(selected) {
      this.loading = true;
      this.setUpcomingTabFilter({ ...this.upcomingTabFilter, practitionerId: selected });
      await this.getPatientUpcomingEncounters();
      this.loading = false;
    },
    async changeCurrentDate(newDate) {
      this.loading = true;
      try {
        this.setUpcomingTabFilter({
          ...this.upcomingTabFilter,
          currentDate: newDate.sort((a, b) => moment(a).valueOf() - moment(b).valueOf()),
        });
        await this.getPatientUpcomingEncounters();
      } catch (e) {
        console.error(e);
        snackBarEventBus.$emit(snackBarEventName, { message: e.message || e, type: "error" });
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.container-filters {
  display: grid;
  grid-gap: 18px;
  grid-template-columns: repeat(auto-fit, minmax(max-content, 335px));
  @include mobile {
    grid-template-columns: repeat(auto-fit, minmax(min-content, 320px));
  }
  margin-bottom: 20px;
}
</style>
