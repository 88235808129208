<template>
  <div class="d-appoinments patient-view">
    <v-row class="d-appoinments_header pb-3" justify="space-between" no-gutters>
      <span v-if="this.$route.meta.layout === 'default'" class="heading-2">{{ $t("visits.visitCenter") }}</span>
      <PrimaryButton
        v-if="this.$route.meta.layout === 'default'"
        :app-view="true"
        :onAppView="true"
        :text="$t('visits.requestVisit')"
        leftIcon="mdi-plus"
        size="medium"
        @onClick="toVisitRequest"
      />
    </v-row>
    <ScheduledAppointments />
  </div>
</template>
<script>
import PrimaryButton from "@/components/uikit/PrimaryButton";
import ScheduledAppointments from "@/views/Patient/Appointments/ScheduledAppointments";

export default {
  name: "AppointmentsView",
  components: {
    PrimaryButton,
    ScheduledAppointments,
  },
  data: () => ({
    tab: null,
    loading: false,
  }),
  computed: {
    tabs() {
      return [
        {
          value: this.$t("visits.upcoming"),
          to: "/patient/visits/upcoming",
        },
        {
          value: this.$t("visits.archive"),
          to: "/patient/visits/archive",
        },
      ];
    },
  },
  methods: {
    async toVisitRequest() {
      await this.$router.push({ path: "/patient/encounters/schedule" });
    },
  },
};
</script>
<style lang="scss">
.patient-view {
  .d-appoinments_header {
    @include tablet {
      display: flex;
      flex-direction: row;
      align-items: center;
      .heading-2 {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 25px;
      }
    }
  }
}
</style>
