<template>
  <div class="pt-10 scheduled">
    <v-tabs v-model="tab" :show-arrows="true" active-class="tabs_tab_active" class="tabs" color="primary">
      <v-tab class="tabs_tab" @click="getListEncounters"> {{ $t("visits.upcoming_short") }}</v-tab>
      <v-tab class="tabs_tab" @click="getPendingCheckoutsList">
        {{ $t("owner.clinician.table.pending") }}
        <v-chip v-if="pendingCount > 0" class="ma-2" color="primary" small>{{ pendingCount }}</v-chip>
      </v-tab>
      <v-tab class="tabs_tab" @click="getArchiveListEncounters"> Past</v-tab>
      <v-tab-item class="patients-list_tabs_tab_item">
        <Upcoming :isLoading="loading" :isSwitchTab="switchBetweenTabs$" @toSchedule="redirectToSchedule" />
      </v-tab-item>
      <v-tab-item class="patients-list_tabs_tab_item">
        <Pending :isLoading="loading" :isSwitchTab="switchBetweenTabs$" @toSchedule="redirectToSchedule" />
      </v-tab-item>
      <v-tab-item class="patients-list_tabs_tab_item">
        <Past
          :isLoading="loading"
          :isSwitchTab="switchBetweenTabs$"
          @toSchedule="redirectToSchedule"
          @update:isLoading="loading = $event"
        />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import moment from "moment-timezone";
import { mapActions, mapState } from "pinia";
import { Subject } from "rxjs";

import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { useAppointmentsStore } from "@/pinia-store/appointments";
import { useAuthStore } from "@/pinia-store/auth";
import { useEncountersStore } from "@/pinia-store/encounters";
import { useOwnersStore } from "@/pinia-store/owners";
import { usePatientStore } from "@/pinia-store/patient";
import { usePatientsStore } from "@/pinia-store/patients";
import Past from "@/views/Patient/Appointments/ScheduledAppointmentComponents/Past";
import Pending from "@/views/Patient/Appointments/ScheduledAppointmentComponents/Pending";
import Upcoming from "@/views/Patient/Appointments/ScheduledAppointmentComponents/Upcoming";

export default {
  name: "ScheduledAppointments",
  components: {
    Upcoming,
    Past,
    Pending,
  },
  data() {
    return {
      tab: null,
      practitionersFullNames: [],
      loading: false,
      switchBetweenTabs$: new Subject(),
    };
  },
  computed: {
    ...mapState(useEncountersStore, ["upcomingTabFilter", "pastTabFilter"]),
    ...mapState(usePatientStore, ["practitioners"]),
    ...mapState(useAuthStore, ["uid", "role", "timeZone"]),
    ...mapState(useAppointmentsStore, ["pendingAppointments", "pendingAppointmentsPagination"]),
    pendingCount() {
      if (!this.pendingAppointments?.length) return 0;
      return this.pendingAppointments.filter((item) => {
        return !this.$moment.tz(this.timeZone).isAfter(item.start) && item.status === "pending";
      }).length;
    },
  },
  methods: {
    ...mapActions(useOwnersStore, ["getPendingCheckouts"]),
    ...mapActions(usePatientsStore, ["getAllPractitioners"]),
    ...mapActions(useEncountersStore, ["getAllEncounters", "getPatientPastEncounters"]),
    ...mapActions(useAppointmentsStore, ["getPendingAppointments", "getPastAppointments", "getUpcomingAppointments"]),
    async getArchiveListEncounters() {
      this.switchBetweenTabs$.next("Past");
      this.loading = true;

      let { currentDate } = this.pastTabFilter;
      const datesSorted = currentDate.sort((a, b) => moment(a).valueOf() - moment(b).valueOf());
      let queryParams = {
        practitionerId: this.upcomingTabFilter.practitionerId,
        patientId: this.uid,
      };
      if (datesSorted.length === 2) {
        queryParams.startDate = datesSorted[0];
        queryParams.endDate = datesSorted[1];
      } else queryParams.date = currentDate[0];

      try {
        await Promise.all([this.getPastAppointments(queryParams)]);
      } catch (e) {
        console.error(e);
        snackBarEventBus.$emit(snackBarEventName, { message: e.message || e, type: "error" });
      }
      this.loading = false;
    },
    async getListEncounters() {
      this.switchBetweenTabs$.next(this.$t("visits.upcoming_short"));
      this.loading = true;
      try {
        this.loading = true;
        let { currentDate } = this.upcomingTabFilter;
        const datesSorted = currentDate.sort((a, b) => moment(a).valueOf() - moment(b).valueOf());
        let queryParams = {
          future: true,
          patientId: this.uid,
          practitionerId: this.upcomingTabFilter.practitionerId,
          includeDependents: true,
        };
        if (datesSorted.length === 2) {
          queryParams.startDate = datesSorted[0];
          queryParams.endDate = datesSorted[1];
        }
        await this.getUpcomingAppointments(queryParams);
      } catch (e) {
        console.error(e);
        snackBarEventBus.$emit(snackBarEventName, { message: e.message || e, type: "error" });
      }
      this.loading = false;
    },
    async getPendingCheckoutsList() {
      this.switchBetweenTabs$.next(this.$t("owner.clinician.table.pending"));
      const startDate = this.$moment.tz(this.timeZone).startOf("isoWeek").weekday(1).format("YYYY-MM-DD");
      const endDate = this.$moment().tz(this.timeZone).add(7, "days").format("YYYY-MM-DD");
      this.loading = true;
      try {
        await this.getPendingAppointments({
          status: "pending",
          startDate,
          endDate,
          patientId: this.uid,
          includeDependents: true,
        });
      } catch (e) {
        console.error(e);
        snackBarEventBus.$emit(snackBarEventName, { message: e.message || e, type: "error" });
      }
      this.loading = false;
    },
    async redirectToSchedule() {
      await this.$router.push({ path: `/${this.role}/encounters/schedule` });
    },
  },
  mounted() {
    this.getAllPractitioners();
    this.getListEncounters();
    this.getPendingCheckoutsList();
  },
};
</script>

<style scoped>
.scheduled {
  @include tablet {
    padding-top: 0 !important;
  }
}
</style>
